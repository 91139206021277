import React, { Fragment, useContext, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { Grid, useMediaQuery } from '@mui/material'

import axios from 'axios'
import { useTheme } from '@mui/material/styles'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import {
  ContainerHeaderH1,
  InfoContainer,
  LaakariButton,
  ShadowContainer,
  SubHeaderH2
} from '../../../components/styled/uiComponents'
import { UserContext } from '../../../context'
import { Job } from '../../../types/job'
import { AuthContext } from '../../../context/AuthProvider'
import { buildApiUrl } from '../../../utils/utils'
import FormButtonContainer from '../../../components/FormButtonContainer'
import MobileTitle from '../MobileTitle'
import Divider from '../Divider'
import { isSessionInvalidError } from '../../../utils/error'
import WorkItem from './WorkItem'
import JobDialog from './JobDialog'
import UpdateJobForm from './UpdateJobForm'
import CreateJobForm from './CreateJobForm'
import JobSummary from './JobSummary'

const WorkInfo = () => {
  const [createJobDialogOpen, setCreateJobDialogOpen] = useState(false)
  const [updateJobDialogOpen, setUpdateJobDialogOpen] = useState(false)
  const [deleteJobDialogOpen, setDeleteJobDialogOpen] = useState(false)
  const [selectedJob, setSelectedJob] = useState<Job | undefined>()
  const [isOpen, setIsOpen] = useState(!isMobile)
  const { user, setUser } = useContext(UserContext)
  const { bearerToken, handleLogout } = useContext(AuthContext)

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const navigate = useNavigate()

  const employments = user?.employments

  const currentEmployments: Job[] = employments
    ?.filter((job: Job) => !job.els_loppupvm)
    .sort((a: Job, b: Job) => new Date(b.els_alkupvm).getTime() - new Date(a.els_alkupvm).getTime())

  const previousEmployments: Job[] = employments
    ?.filter((job: Job) => job.els_loppupvm)
    .sort((a: Job, b: Job) => new Date(b.els_alkupvm).getTime() - new Date(a.els_alkupvm).getTime())

  const handleCloseDialog = (type: 'create' | 'update' | 'delete') => {
    switch (type) {
      case 'create':
        setCreateJobDialogOpen(false)
        break
      case 'delete':
        setDeleteJobDialogOpen(false)
        break
      case 'update':
        setUpdateJobDialogOpen(false)
        break
    }
    setSelectedJob(undefined)
  }

  const handleDeleteJob = async () => {
    try {
      if (selectedJob) {
        const { els_tyosuhdeid } = selectedJob
        const url = buildApiUrl(`employment`)
        await axios.delete(url, {
          data: { els_tyosuhdeid },
          headers: { 'x-tietoni-token': `Bearer ${bearerToken}` }
        })
        setUser({
          ...user,
          employments: employments.filter((job: Job) => job.els_tyosuhdeid !== els_tyosuhdeid)
        })
        handleCloseDialog('delete')
      }
    } catch (error) {
      console.log(error)
      if (isSessionInvalidError(error)) {
        handleCloseDialog('delete')
        toast.error('Istunto on päättynyt. Kirjaudu uudelleen sisään.')
        handleLogout(() => navigate('/'))
      }
    }
  }

  return (
    <>
      <Divider id='work' isMobile={isMobile} />
      <InfoContainer>
        <Grid container justifyContent='flex-end' alignItems='center'>
          {isMobile ? (
            <MobileTitle isOpen={isOpen} setIsOpen={setIsOpen} title='Työpaikkatiedot' />
          ) : (
            <>
              <Grid item xs={12} md={9} lg={10}>
                <ContainerHeaderH1>Työpaikkatiedot</ContainerHeaderH1>
              </Grid>
              <Grid item xs={12} md={3} lg={2} style={{ textAlign: 'right' }}>
                <LaakariButton onClick={() => setCreateJobDialogOpen(true)}>
                  Lisää työpaikka
                </LaakariButton>
              </Grid>
            </>
          )}
        </Grid>
        {isOpen && (
          <>
            {isMobile && (
              <LaakariButton onClick={() => setCreateJobDialogOpen(true)}>
                Lisää työpaikka
              </LaakariButton>
            )}
            <Grid container alignItems='center'>
              <Grid item xs={12} md={3}>
                <SubHeaderH2>Nykyiset</SubHeaderH2>
              </Grid>
              {currentEmployments &&
                currentEmployments.map((job: Job) => (
                  <WorkItem
                    isMobile={isMobile}
                    fullScreen={fullScreen}
                    key={job.els_tyosuhdeid}
                    job={job}
                    deleteAction={() => {
                      setSelectedJob(job)
                      setDeleteJobDialogOpen(true)
                    }}
                    editAction={() => {
                      setSelectedJob(job)
                      setUpdateJobDialogOpen(true)
                    }}
                  />
                ))}
            </Grid>
            <Grid container alignItems='center'>
              <Grid item xs={12}>
                <SubHeaderH2>Aikaisemmat</SubHeaderH2>
              </Grid>

              {previousEmployments?.map((job: Job, i: number) => (
                <WorkItem
                  isMobile={isMobile}
                  fullScreen={fullScreen}
                  key={i}
                  job={job}
                  deleteAction={() => {
                    setSelectedJob(job)
                    setDeleteJobDialogOpen(true)
                  }}
                  editAction={() => {
                    setSelectedJob(job)
                    setUpdateJobDialogOpen(true)
                  }}
                />
              ))}
            </Grid>
          </>
        )}
      </InfoContainer>
      <Grid container>
        <Grid item xs={12}>
          <JobDialog
            fullScreen={fullScreen}
            open={createJobDialogOpen}
            title='Täytä työpaikkatiedot'
            content={<CreateJobForm closeDialog={() => handleCloseDialog('create')} />}
            closeDialog={() => handleCloseDialog('create')}
          />
        </Grid>
        {selectedJob && (
          <Grid item xs={12}>
            <JobDialog
              fullScreen={fullScreen}
              open={updateJobDialogOpen}
              title='Muokkaa työpaikkatietoja'
              content={
                <UpdateJobForm closeDialog={() => handleCloseDialog('update')} job={selectedJob} />
              }
              closeDialog={() => handleCloseDialog('update')}
            />
          </Grid>
        )}
        {selectedJob && (
          <Grid item xs={12}>
            <JobDialog
              fullScreen={fullScreen}
              open={deleteJobDialogOpen}
              title=''
              content={
                <Fragment>
                  <ShadowContainer>
                    <SubHeaderH2>Työpaikkatiedot, jota olet poistamassa</SubHeaderH2>
                    <JobSummary job={selectedJob} />
                  </ShadowContainer>
                  <FormButtonContainer align='right'>
                    <LaakariButton
                      style={{ marginTop: 16, marginBottom: 16 }}
                      variant='outlined'
                      onClick={() => handleCloseDialog('delete')}>
                      Takaisin
                    </LaakariButton>
                    <LaakariButton onClick={() => handleDeleteJob()}>Poista</LaakariButton>
                  </FormButtonContainer>
                </Fragment>
              }
              closeDialog={() => handleCloseDialog('delete')}
            />
          </Grid>
        )}
      </Grid>
    </>
  )
}

export default WorkInfo
