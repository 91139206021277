import React, { useContext, useState } from 'react'
import {
  FormControlLabel,
  FormLabel,
  IconButton,
  LinearProgress,
  MenuItem,
  Radio,
  RadioGroup,
  Tooltip,
  Grid,
  Select
} from '@mui/material'
import axios from 'axios'
import { Controller, useForm } from 'react-hook-form'
import DeleteIcon from '@mui/icons-material/Delete'
import { format } from 'date-fns'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { AuthContext } from '../../../context/AuthProvider'
import JobResultRow from '../../../components/JobResultRow'
import {
  ErrorMessage,
  FormInput,
  LaakariButton,
  ShadowContainer,
  SubHeaderH3
} from '../../../components/styled/uiComponents'
import { MetadataContext } from '../../../context'
import { IJobTitle, IWorkField } from '../../../types'
import { Job, UpdateJobFormData } from '../../../types/job'
import { buildApiUrl } from '../../../utils/utils'
import { isSessionInvalidError } from '../../../utils/error'

const formatJobValue = ({
  els_alkupvm,
  els_loppupvm,
  tyosuhdetoimiala: { attributevalue },
  els_paatoimi,
  ammattinimike: { els_koodiid }
}: Job): UpdateJobFormData => {
  return {
    els_paatoimi: els_paatoimi ? 'yes' : 'no',
    els_alkupvm,
    els_loppupvm,
    els_tyosuhdetoimiala: attributevalue,
    els_ammattinimike: els_koodiid
  }
}

interface Props {
  job: Job
  closeDialog: () => void
}

const UpdateJobForm: React.FC<Props> = ({ job, closeDialog }) => {
  const defaultValues = formatJobValue(job)

  const { metaData } = useContext(MetadataContext)
  const { bearerToken, evaluateToken, handleLogout } = useContext(AuthContext)
  const [submitError, setSubmitError] = useState(false)
  const navigate = useNavigate()
  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitting }
  } = useForm<UpdateJobFormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues
  })

  const onSubmit = async (data: UpdateJobFormData) => {
    try {
      if (submitError) {
        setSubmitError(false)
      }

      // if end date is not set, remove it from the data object
      if (!data.els_loppupvm) {
        delete data.els_loppupvm
      }

      const { els_tyosuhdeid } = job
      const url = buildApiUrl(`employment`)
      await axios.put(
        url,
        { ...data, els_tyosuhdeid },
        {
          headers: { 'x-tietoni-token': `Bearer ${bearerToken}` }
        }
      )
      evaluateToken()
      closeDialog()
      // TODO append updated employment data to UserContext
    } catch (error) {
      setSubmitError(true)
      console.log('error when submitting form', error)
      if (isSessionInvalidError(error)) {
        closeDialog()
        toast.error('Istunto on päättynyt. Kirjaudu uudelleen sisään.')
        handleLogout(() => navigate('/'))
      }
    }
  }

  const today = format(new Date(), 'yyyy-MM-d')

  return metaData ? (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ShadowContainer style={{ marginBottom: 8 }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <SubHeaderH3>Työpaikka</SubHeaderH3>
              </Grid>
              <Grid item xs={12}>
                <JobResultRow jobInfo={job.els_nimi} />
              </Grid>
              <Grid item xs={12}>
                <FormLabel>Toimiala</FormLabel>
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name='els_tyosuhdetoimiala'
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      displayEmpty
                      fullWidth
                      onChange={e => onChange(e.target.value)}
                      value={value || ''}>
                      <MenuItem value=''>{'---Valitse---'}</MenuItem>
                      {metaData.toimialat.map(({ attributevalue, value }: IWorkField) => (
                        <MenuItem key={attributevalue} value={attributevalue}>
                          {value}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <FormLabel>Onko tämä päätoiminen työpaikkasi?</FormLabel>
                <Controller
                  name='els_paatoimi'
                  control={control}
                  defaultValue='yes'
                  render={({ field: { onChange, value } }) => (
                    <RadioGroup onChange={e => onChange(e.target.value)} value={value}>
                      <FormControlLabel value='yes' control={<Radio />} label='Kyllä' />
                      <FormControlLabel value='no' control={<Radio />} label='Ei' />
                    </RadioGroup>
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <FormLabel>Ammattinimike</FormLabel>
                <Controller
                  name='els_ammattinimike'
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      displayEmpty
                      fullWidth
                      onChange={e => onChange(e.target.value)}
                      value={value || ''}>
                      <MenuItem value=''>{'---Valitse---'}</MenuItem>
                      {metaData.ammattinimikkeet.map(
                        ({ els_koodiid, els_koodinnimi }: IJobTitle) => (
                          <MenuItem key={els_koodiid} value={els_koodiid}>
                            {els_koodinnimi}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <FormLabel>Aloituspäivämäärä</FormLabel>
                <FormInput {...register('els_alkupvm')} type='date' max={today} />
              </Grid>
              <Grid container item xs={12} alignItems='center'>
                <Grid item xs={12}>
                  <FormLabel>Päättymispäivämäärä</FormLabel>
                </Grid>

                <FormInput {...register('els_loppupvm')} type='date' max={today} />
                <Tooltip title='Poista Päättymispäivämäärä'>
                  <IconButton onClick={() => setValue('els_loppupvm', undefined)}>
                    <DeleteIcon color='error' />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
            {submitError && (
              <ErrorMessage>Jotain meni pieleen muokattaessa työpaikkaa</ErrorMessage>
            )}
          </ShadowContainer>
          <Grid
            container
            spacing={3}
            direction='row'
            wrap='wrap-reverse'
            alignItems='center'
            justifyContent='space-between'
            sx={{ paddingTop: '3vh' }}>
            <Grid item xs={12} md={4}>
              <LaakariButton variant='outlined' onClick={closeDialog} disabled={isSubmitting}>
                Takaisin
              </LaakariButton>
            </Grid>
            <Grid item xs={12} md={4}>
              <LaakariButton type='submit' disabled={isSubmitting}>
                Lähetä
              </LaakariButton>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  ) : (
    <LinearProgress />
  )
}

export default UpdateJobForm
