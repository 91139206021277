import { yupResolver } from '@hookform/resolvers/yup'
import { CircularProgress, Grid, Typography } from '@mui/material'
import axios from 'axios'
import React, { useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import { AuthContext } from '../../../../context/AuthProvider'
import PageHeader from '../../../../components/PageHeader'
import {
  ErrorMessage,
  FormInput,
  FormLabel,
  LaakariButton,
  ShadowContainer
} from '../../../../components/styled/uiComponents'
import { isSessionInvalidError } from '../../../../utils/error'

import { buildApiUrl, validateIBAN } from '../../../../utils/utils'
import FormSentConfirmation from '../../FormSentConfirmation'

interface Props {
  returnToActionChoice: (formChoice: string | null) => void
}
const validationSchema = yup.object().shape({
  bankAccount: yup
    .string()
    .required('Täytä puuttuva kenttä')
    .test('valid-iban', 'Syötä tilinumero IBAN-muodossa', validateIBAN)
})

const PaymentReturnForm: React.FC<Props> = ({ returnToActionChoice }) => {
  const [formSent, setFormSent] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const { bearerToken, handleLogout } = useContext(AuthContext)
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<{ bankAccount: string }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema)
  })

  const onSubmit = async (data: any) => {
    try {
      setIsLoading(true)
      const url = buildApiUrl('discount-application')
      const response = await axios.post(url, data, {
        headers: { 'x-tietoni-token': `Bearer ${bearerToken}` }
      })
      console.log('res: ', response.data)
      setIsLoading(false)
      setFormSent(true)
    } catch (error) {
      setIsLoading(false)
      console.log('error when submitting form: ', error)
      if (isSessionInvalidError(error)) {
        toast.error('Istunto on päättynyt. Kirjaudu uudelleen sisään.')
        handleLogout(() => navigate('/'))
      }
    }
  }

  return (
    <>
      {formSent ? (
        <FormSentConfirmation
          formName='Jäsenmaksupalautus'
          longInfo='Tietosi ovat tallennettu'
          subUrl='#membership'
        />
      ) : (
        <>
          <PageHeader />
          <div style={{ padding: '3vh' }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={8}>
                <Typography variant='h3' color='primary'>
                  Jäsenmaksupalautus
                </Typography>
              </Grid>
              <Grid item xs={12} md={8}>
                <p>Täytä tämä lomake vain jos sinua on pyydetty erikseen tekemään näin.</p>
              </Grid>
              <Grid item xs={12} md={8}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <ShadowContainer>
                    <Grid item xs={12} md={6}>
                      <FormLabel>Tilinumero jäsenmaksun mahdollista palautusta varten</FormLabel>
                      <FormInput {...register('bankAccount')} />
                      <ErrorMessage>{errors.bankAccount?.message}</ErrorMessage>
                    </Grid>
                  </ShadowContainer>
                  <Grid
                    container
                    spacing={3}
                    direction='row'
                    wrap='wrap-reverse'
                    alignItems='center'
                    justifyContent='space-between'
                    sx={{ paddingTop: '3vh' }}>
                    <Grid item xs={12} md={3} style={{ paddingTop: '3vh' }}>
                      <LaakariButton variant='outlined' onClick={() => returnToActionChoice(null)}>
                        Takaisin
                      </LaakariButton>
                    </Grid>
                    <Grid item xs={12} md={3} style={{ paddingTop: '3vh', textAlign: 'right' }}>
                      <LaakariButton type='submit' disabled={isLoading}>
                        {isLoading ? <CircularProgress size={24.5} /> : 'Lähetä'}
                      </LaakariButton>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
            </Grid>
          </div>
        </>
      )}
    </>
  )
}

export default PaymentReturnForm
