const getTokenLocalStorage = () => localStorage.getItem('token')

const putTokenLocalStorage = (id: string) => localStorage.setItem('token', id)

const removeTokenLocalStorage = () => localStorage.removeItem('token')

const getAdminTokenLocalStorage = () => localStorage.getItem('adminToken')

const putAdminTokenLocalStorage = (id: string) => localStorage.setItem('adminToken', id)

const removeAdminTokenLocalStorage = () => localStorage.removeItem('adminToken')

const getInviteHashLocalStorage = () => localStorage.getItem('inviteHash')

const putInviteHashLocalStorage = (inviteHash: string) =>
  localStorage.setItem('inviteHash', inviteHash)

const removeInviteHashLocalStorage = () => localStorage.removeItem('inviteHash')

const getStudentFormValuesLocalStorage = (hashedHetu: string) => {
  return localStorage.getItem('studentFormValues_' + hashedHetu)
}

const putStudentFormValuesLocalStorage = (hashedHetu: string, formValues: any) => {
  localStorage.setItem('studentFormValues_' + hashedHetu, formValues)
}

const removeStudentFormValuesLocalStorage = (hashedHetu: string) => {
  localStorage.removeItem('studentFormValues_' + hashedHetu)
}

export {
  getTokenLocalStorage,
  putTokenLocalStorage,
  removeTokenLocalStorage,
  getAdminTokenLocalStorage,
  putAdminTokenLocalStorage,
  removeAdminTokenLocalStorage,
  getInviteHashLocalStorage,
  putInviteHashLocalStorage,
  removeInviteHashLocalStorage,
  getStudentFormValuesLocalStorage,
  putStudentFormValuesLocalStorage,
  removeStudentFormValuesLocalStorage
}
