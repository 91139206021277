import * as yup from 'yup'

const phoneRegEx = /^\+\d+$/

const personalInfoSchema = yup
  .object({
    lastname: yup.string().required(),
    firstname: yup.string().max(50).required(),
    els_hetu: yup.string().required(),
    nickname: yup.string().required('Täytä puuttuva kenttä'),
    address: yup.object().shape({
      line1: yup.string().max(250).required('Täytä puuttuva kenttä'),
      line2: yup.string().max(250).optional(),
      line3: yup.string().max(250).optional(),
      postalcode: yup.string().max(20).required('Täytä puuttuva kenttä'),
      country: yup.string().required('Täytä puuttuva kenttä'),
      city: yup.string().max(80).required('Täytä puuttuva kenttä'),
      els_postikieli: yup.string().required('Täytä puuttuva kenttä')
    }),
    mobilephone: yup
      .string()
      .max(50)
      .matches(phoneRegEx, 'Tarkista puhelinnumeron muoto')
      .required(),
    emailaddress1: yup
      .string()
      .max(100)
      .email('Tarkista sähköpostiosoitteen muoto')
      .required('Täytä puuttuva kenttä'),
    cursus: yup.object().shape({
      els_oppilaitos: yup.string().required('Täytä puuttuva kenttä'),
      els_aloitusvuosi: yup.string().required('Täytä puuttuva kenttä'),
      els_lukukausi: yup.string().required('Täytä puuttuva kenttä')
    }),
    school: yup.string()
  })
  .required()

/** Empty schema since Summary page doesn't have any fields */
const summarySchema = yup.object().shape({})

export const studentFormSchema = [
  // page 0
  personalInfoSchema,
  // page 1
  summarySchema
]
