import React from 'react'
import { Grid, Typography } from '@mui/material'

import { useNavigate } from 'react-router-dom'
import PageHeader from '../../components/PageHeader'
import { LaakariButton, ShadowTableRow } from '../../components/styled/uiComponents'
import ButtonToFrontpage from '../../components/ButtonToFrontpage'
import { useAuth } from '../../context/AuthProvider'

interface Props {
  formName: string
  longInfo?: string
  subUrl?: string
}

const FormSentConfirmation: React.FC<Props> = ({ formName, longInfo, subUrl }) => {
  const auth = useAuth()
  const navigate = useNavigate()

  const handleLogout = () => {
    auth.handleLogout(() => navigate('/'))
  }

  // if student form
  const studentForm = formName === 'Ilmoita tietosi Lääkäriliittoon'

  return (
    <>
      <PageHeader />
      <div style={{ padding: '3vh' }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Typography variant='h3' color='primary'>
              {formName}
            </Typography>
          </Grid>
          <Grid container item xs={12} md={8}>
            <ShadowTableRow>
              {studentForm ? <p>Kiitos tietojesi ilmoittamisesta!</p> : <p>Kiitos.</p>}
              {longInfo ? (
                longInfo
              ) : studentForm ? (
                <p>Saat Fimnet-tunnukset ja ensimmäisen Lääkärilehtesi lähiviikkojen aikana.</p>
              ) : (
                <p>Hakemus on lähetetty ja siirtyy seuraavaksi toimihenkilön käsiteltäväksi.</p>
              )}
            </ShadowTableRow>
            <Grid container direction='row-reverse' justifyContent='flex-start'>
              <Grid item xs={12} md={3} style={{ textAlign: 'right' }}>
                {studentForm ? (
                  <LaakariButton onClick={handleLogout}>Kirjaudu ulos</LaakariButton>
                ) : (
                  <ButtonToFrontpage subUrl={subUrl} formName={formName} />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default FormSentConfirmation
