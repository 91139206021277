import React, { useEffect, useState, useContext } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import axios from 'axios'

import { toast } from 'react-toastify'
import { buildApiUrl } from '../utils/utils'
import {
  getTokenLocalStorage,
  putTokenLocalStorage,
  removeTokenLocalStorage,
  removeInviteHashLocalStorage,
  getInviteHashLocalStorage
} from '../utils/localStorage'
import { UserContext } from './UserContext'

interface AuthContextType {
  sessionId: string | null
  bearerToken: string | null
  handleLogin: (callback: VoidFunction) => void
  handleLogout: (callback: VoidFunction) => void
  isLoadingAuth: boolean
  evaluateToken: () => void
}

export const AuthContext = React.createContext<AuthContextType>(null!)

export const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const tokenLocalStorage = getTokenLocalStorage()
  const [sessionId, setSessionId] = useState<string | null>(null)
  const [bearerToken, setBearerToken] = useState<string | null>(tokenLocalStorage)
  const [isLoadingAuth, setIsLoadingAuth] = useState<boolean>(false)

  const { setUser, setMerchState } = useContext(UserContext)

  const [searchParams] = useSearchParams()

  const navigate = useNavigate()

  const handleLogin = (callback: VoidFunction) => {
    setIsLoadingAuth(true)
    axios
      .post(buildApiUrl('sessions'), {
        successUrl: process.env.REACT_APP_LOGIN_REDIRECT_URL
      })
      // .post(`https:/localhost:8080/api/sessions?key=${process.env.REACT_APP_API_KEY}`, {
      //   successUrl: process.env.REACT_APP_LOGIN_REDIRECT_URL
      // })
      .then(response => {
        window.location.replace(response.data.Location)
      })
    callback()
  }

  const handleLogout = (callback: VoidFunction) => {
    removeTokenLocalStorage()
    setBearerToken(null)
    removeInviteHashLocalStorage()
    callback()
  }

  const handleFetchBearerToken = async (sessionId: string) => {
    try {
      setIsLoadingAuth(true)
      const response = await axios.get(buildApiUrl('sessions', sessionId))
      console.log('sessionsResponse: ', response)
      setBearerToken(response.data.token)
      putTokenLocalStorage(response.data.token)
      setUser(response.data.personData)
      setMerchState(response.data.personData.memberProducts)

      // TODO better way to handle this?
      const inviteHash = getInviteHashLocalStorage()

      if (inviteHash) {
        navigate('/studentForm')
      } else {
        navigate('/')
      }

      setIsLoadingAuth(false)
    } catch (error) {
      console.log(error)
      setIsLoadingAuth(false)
    }
  }

  const evaluateToken = async () => {
    try {
      if (bearerToken) {
        const response = await axios.get(buildApiUrl('sessions/evaluateToken'), {
          headers: { 'x-tietoni-token': `Bearer ${bearerToken}` }
        })
        setUser(response.data.personData)
      }
    } catch (error) {
      // Maybe notify user that login has expired and needs to login again?
      // react-tostify, material-ui snackbar etc...
      console.log('Bearer token not valid, removing from storage')
      removeTokenLocalStorage()
      setBearerToken(null)
      console.log(error)
      removeInviteHashLocalStorage()
    }
  }

  useEffect(() => {
    evaluateToken()
  }, [])

  useEffect(() => {
    setSessionId(searchParams.get('sessionId'))
  }, [])

  useEffect(() => {
    if (sessionId) {
      handleFetchBearerToken(sessionId)
    }
  }, [sessionId])

  const value = { sessionId, bearerToken, handleLogin, handleLogout, isLoadingAuth, evaluateToken }
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export default AuthProvider
