import React, { useEffect } from 'react'
import { useMatch, useNavigate } from 'react-router-dom'
import { useAuth } from '../context/AuthProvider'
import { getInviteHashLocalStorage, putInviteHashLocalStorage } from '../utils/localStorage'

const StudentInvite = () => {
  const match = useMatch('/student_invite/:inviteHash')
  const inviteHash = match?.params.inviteHash

  const auth = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    if (inviteHash) {
      const existingInviteHash = getInviteHashLocalStorage()

      if (!existingInviteHash) {
        putInviteHashLocalStorage(inviteHash)
      }

      if (auth.bearerToken) {
        navigate('/studentForm')
      } else {
        navigate('/login')
      }
    }
  }, [inviteHash, auth.bearerToken, navigate])

  return (
    <>
      <p>Ohjataan...</p>
    </>
  )
}

export default StudentInvite
