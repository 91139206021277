import React from 'react'
import { useNavigate } from 'react-router-dom'
import { removeInviteHashLocalStorage } from '../utils/localStorage'
import { LaakariButton } from './styled/uiComponents'

interface Props {
  subUrl?: string
  formName?: string
}

const ButtonToFrontpage: React.FC<Props> = ({ subUrl, formName }) => {
  const navigate = useNavigate()
  const handleNavigateFrontpage = () => {
    if (formName == 'Ilmoittaudu lääketieteen opiskelijaksi') {
      removeInviteHashLocalStorage()
    }
    subUrl ? navigate(`/${subUrl}`) : navigate('/')
  }
  return <LaakariButton onClick={handleNavigateFrontpage}>Palaa etusivulle</LaakariButton>
}

export default ButtonToFrontpage
