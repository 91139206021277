import React, { useEffect } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'

import LandingPage from '../containers/FrontPage'
import LoginPage from '../containers/LoginPage'

import AdminRoute from '../containers/adminView/AdminRoute'

import StudentForm from '../containers/Forms/StudentForm/index'
import EmploymentForm from '../containers/Forms/EmploymentForm'
import EmploymentFormP2 from '../containers/Forms/EmploymentForm/EmploymentFormP2'
import StudentFormResults from '../containers/Forms/StudentFormResults'
import FormSentConfirmation from '../containers/Forms/FormSentConfirmation'
import UpdatePersonalInfo from '../containers/Forms/UpdatePersonalInfo/index'
import MerchandiceForm from '../containers/Forms/MerchandiceForm'
import MembershipForm from '../containers/Forms/MembershipForm'
import MembershipAction from '../containers/Forms/MembershipAction'
import AdminView from '../containers/adminView'
import AdminLogin from '../containers/adminView/AdminLogin'

import MemberCard from '../memberCard/MemberCard'
import StudentInvite from '../containers/StudentInvite'
import PublicOutlet from './PublicOutlet'
import PrivateOutlet from './PrivateOutlet'
import PageNotFound from './PageNotFound'

const AppRouter = () => {
  const { pathname, hash, key } = useLocation()

  useEffect(() => {
    if (hash === '') {
      window.scrollTo(0, 0)
    } else {
      setTimeout(() => {
        const id = hash.replace('#', '')
        const element = document.getElementById(id)
        if (element) {
          element.scrollIntoView()
        }
      }, 0)
    }
  }, [pathname, hash, key])
  return (
    <Routes>
      <Route
        path='admin'
        element={
          <AdminRoute>
            <AdminView />
          </AdminRoute>
        }
      />
      <Route path='adminLogin' element={<AdminLogin />} />

      <Route path='/' element={<PrivateOutlet />}>
        <Route index element={<LandingPage />} />
        <Route path='studentForm' element={<StudentForm />} />
        <Route path='p2' element={<EmploymentForm />} />
        <Route path='p3' element={<EmploymentFormP2 />} />
        <Route path='results' element={<StudentFormResults />} />
        <Route path='updateInfo' element={<UpdatePersonalInfo />} />
        <Route path='merch' element={<MerchandiceForm />} />
        <Route path='memberAction' element={<MembershipAction />} />
        <Route path='membershipApplication' element={<MembershipForm />} />
        <Route path='memberCard' element={<MemberCard />} />
        <Route
          path='formSent'
          element={<FormSentConfirmation formName='Ilmoittaudu lääketieteen opiskelijaksi' />}
        />
      </Route>

      <Route
        path='login'
        element={
          <PublicOutlet>
            <LoginPage />
          </PublicOutlet>
        }
      />
      <Route path='student_invite/:inviteHash' element={<StudentInvite />} />
      <Route path='*' element={<PageNotFound />} />
    </Routes>
  )
}

export default AppRouter
