import { FormLabel, Grid, Typography } from '@mui/material'
import React, { Fragment } from 'react'
import { ResultsP } from '../../../components/styled/uiComponents'

interface Props {
  formState: any
}

const Summary: React.FC<Props> = ({ formState }) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant='h5' color='primary'>
          Henkilötiedot
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FormLabel>Sukunimi</FormLabel>
        <ResultsP>{formState.lastname}</ResultsP>
      </Grid>
      <Grid item xs={12}>
        <FormLabel>Etunimet</FormLabel>
        <ResultsP>{formState.firstname}</ResultsP>
      </Grid>
      {formState.nickname && (
        <Grid item xs={12}>
          <FormLabel>Kutsumanimi</FormLabel>
          <ResultsP>{formState.nickname}</ResultsP>
        </Grid>
      )}
      <Grid item xs={12}>
        <FormLabel>Henkilötunnus</FormLabel>
        <ResultsP>{formState.els_hetu}</ResultsP>
      </Grid>
      <Grid item xs={12}>
        <div style={{ borderTop: '1px dotted black' }}>
          <Typography variant='h5' color='primary' sx={{ paddingTop: '3vh' }}>
            Yhteystiedot
          </Typography>
        </div>
      </Grid>
      <Grid item xs={12}>
        <FormLabel>Postiosoite</FormLabel>
        <ResultsP>{formState.address.line1}</ResultsP>
      </Grid>
      {formState.address.line2 && (
        <Grid item xs={12}>
          <FormLabel>Postiosoitteen rivi 2</FormLabel>
          <ResultsP>{formState.address.line2}</ResultsP>
        </Grid>
      )}
      {formState.address.line3 && (
        <Grid item xs={12}>
          <FormLabel>Postiosoitteen rivi 3</FormLabel>
          <ResultsP>{formState.address.line3}</ResultsP>
        </Grid>
      )}
      <Grid item xs={12}>
        <FormLabel>Postinumero</FormLabel>
        <ResultsP>{formState.address.postalcode}</ResultsP>
      </Grid>
      <Grid item xs={12}>
        <FormLabel>Postitoimipaikka</FormLabel>
        <ResultsP>{formState.address.city}</ResultsP>
      </Grid>
      <Grid item xs={12}>
        <FormLabel>Postituskieli</FormLabel>
        <ResultsP>{JSON.parse(formState.address.els_postikieli).value}</ResultsP>
      </Grid>
      <Grid item xs={12}>
        <FormLabel>Maa</FormLabel>
        <ResultsP>{JSON.parse(formState.address.country).els_koodinnimi}</ResultsP>
      </Grid>
      <Grid item xs={12}>
        <FormLabel>Matkapuhelinnumero</FormLabel>
        <ResultsP>{formState.mobilephone}</ResultsP>
      </Grid>
      <Grid item xs={12}>
        <FormLabel>Sähköpostiosoite</FormLabel>
        <ResultsP>{formState.emailaddress1}</ResultsP>
      </Grid>
      <Grid item xs={12}>
        <div style={{ borderTop: '1px dotted black' }}>
          <Typography variant='h5' color='primary' sx={{ paddingTop: '3vh' }}>
            Opiskelutiedot
          </Typography>
        </div>
      </Grid>
      {formState?.cursus?.els_oppilaitos && (
        <>
          <Grid item xs={12}>
            <FormLabel>Oppilaitos</FormLabel>
            <ResultsP>{JSON.parse(formState.cursus.els_oppilaitos).els_koodinnimi}</ResultsP>
          </Grid>
          <Grid item xs={12}>
            <FormLabel>Aloitusvuosi</FormLabel>
            <ResultsP>{formState.cursus.els_aloitusvuosi}</ResultsP>
          </Grid>
          <Grid item xs={12}>
            <FormLabel>Lukukausi</FormLabel>
            <ResultsP>{JSON.parse(formState.cursus.els_lukukausi).value}</ResultsP>
          </Grid>
        </>
      )}
    </Grid>
  )
}
export default Summary
