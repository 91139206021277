import React, { useContext, useState } from 'react'
import {
  CircularProgress,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography
} from '@mui/material'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import { compareAsc, format } from 'date-fns'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import FormStepper from '../../../../components/FormStepper'
import PageHeader from '../../../../components/PageHeader'
import {
  ShadowContainer,
  LaakariButton,
  FormInput,
  FormLabel,
  ErrorMessage
} from '../../../../components/styled/uiComponents'
import { buildApiUrl, validateIBAN } from '../../../../utils/utils'
import FormSentConfirmation from '../../FormSentConfirmation'
import { AuthContext } from '../../../../context/AuthProvider'
import { MembershipContext } from '../../../../context'
import { isSessionInvalidError } from '../../../../utils/error'
import ResignationSummary from './ResignationSummary'

interface ResignationFormParams {
  membership: any
  timeOfResignation: string
  bankAccount: string
}

const labelFromDate = (d: Date) => format(d, 'd.M.y')

const currentYear = new Date().getFullYear()
const nextYear = new Date().getFullYear() + 1

const summerLastDay = new Date(`${currentYear}-06-30`)
const summerNextYear = new Date(`${nextYear}-06-30`)

const firstHalfOver = compareAsc(new Date(), summerLastDay) === 1 ? true : false

const paymentPeriodEnd = !firstHalfOver ? summerLastDay : summerNextYear

const endOfYearValue = `${currentYear}-12-31`
const endOfYearLabel = labelFromDate(new Date(endOfYearValue))

const options = !firstHalfOver
  ? [
      { label: endOfYearLabel, value: endOfYearValue },
      {
        label: `Jäsenmaksukauden loppuun ${labelFromDate(paymentPeriodEnd)}`,
        value: format(paymentPeriodEnd, 'yyyy-MM-dd')
      },
      { label: 'Valitse päivä', value: 'Valitse päivä' }
    ]
  : [
      { label: endOfYearLabel, value: endOfYearValue },
      { label: 'Valitse päivä', value: 'Valitse päivä' }
    ]

interface Props {
  selectedOrg: any
  returnToActionChoice: (formChoice: string | null) => void
}

const validationSchema = yup.object().shape({
  membership: yup
    .object()
    .shape({
      els_jasentyyppi: yup.string().required(),
      els_liitonjasenyysid: yup.string().required(),
      yhdistys_name: yup.string().required()
    })
    .required(),
  timeOfResignation: yup.string().required('Täytä puuttuva kenttä'),
  bankAccount: yup
    .string()
    .required('Täytä puuttuva kenttä')
    .test('valid-iban', 'Syötä tilinumero IBAN-muodossa', validateIBAN)
})

const ResignationForm: React.FC<Props> = ({ returnToActionChoice }) => {
  const [page, setPage] = useState(0)
  const [selectedOption, setSelectedOption] = useState(endOfYearValue)
  const [formSent, setFormSent] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const { bearerToken, handleLogout } = useContext(AuthContext)
  const { contextMembership } = useContext(MembershipContext)
  const navigate = useNavigate()

  const {
    register,
    getValues,
    trigger,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm<ResignationFormParams>({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      membership: contextMembership,
      timeOfResignation: endOfYearValue,
      bankAccount: ''
    }
  })

  const handleChangeOption = (e: any) => {
    const val = e.target.value
    if (val !== 'Valitse päivä') {
      setValue('timeOfResignation', val)
    } else {
      setValue('timeOfResignation', '')
    }
    setSelectedOption(e.target.value)
  }

  const handleFormReset = () => {
    returnToActionChoice(null)
  }

  const handleNextStep = async () => {
    const isStepValid = await trigger()
    if (isStepValid) {
      setPage(prevActivePage => prevActivePage + 1)
    }
  }

  const onSubmit = async (data: any) => {
    try {
      const newData = {
        ...data
      }

      setIsLoading(true)
      const url = buildApiUrl('resignation-notice')
      const response = await axios.post(url, newData, {
        headers: { 'x-tietoni-token': `Bearer ${bearerToken}` }
      })
      console.log('res: ', response.data)
      setIsLoading(false)
      setFormSent(true)
    } catch (error) {
      setIsLoading(false)
      console.log('error when submitting form: ', error)
      if (isSessionInvalidError(error)) {
        toast.error('Istunto on päättynyt. Kirjaudu uudelleen sisään.')
        handleLogout(() => navigate('/'))
      }
    }
  }

  const today = format(new Date(), 'yyyy-MM-d')

  return (
    <>
      {formSent ? (
        <FormSentConfirmation
          formName='Jäsenyyden päättäminen'
          longInfo='Eroilmoitus vastaanotettu. Jäsenyytesi päättyyilmoittamanasi päivämääränä. Olemme lähettäneet sähköpostiisi linkin verkkokyselyyn, jolla tilastoimme syitä eroamisiin. Pyydämme, että vastaisit kyselyyn toiminnan parantamiseksi.'
        />
      ) : (
        <>
          <PageHeader />
          <div style={{ padding: '3vh' }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={8}>
                <Typography variant='h3' color='primary'>
                  Jäsenyyden päättäminen
                </Typography>
                <p>
                  Maksamatta jääneistä jäsenmaksuista lähetetään loppulasku. Huomaathan, että
                  jäsenyyteesi kuuluvien vakuutusten voimassaolo päättyy jäsenyytesi viimeiseen
                  päivään, myös muut jäsenedut lakkaavat (huom: fimnet-sähköposti lakkaa 3kk
                  eroamisen jälkeen).
                </p>
              </Grid>

              <Grid item xs={12} md={8}>
                <FormStepper activeStep={page} stepsCount={2} />
                <form onSubmit={handleSubmit(onSubmit)}>
                  <ShadowContainer>
                    {page === 0 ? (
                      <>
                        <div style={{ paddingBottom: '3vh' }}>
                          <p> Huomaathan: </p>
                          <p> Jäsenmaksu jäsenyyden päättyessä </p>
                          <p>
                            1.1.– 30.6. välisenä aikana eroava maksaa puolen vuoden jäsenmaksun.
                          </p>
                          <p>1.7.– 31.12. välisenä aikana eroava maksaa koko vuoden jäsenmaksun.</p>
                          <p>
                            Jäsenyyttä ei voi päättää takautuvasti. Maksamatta jääneistä
                            jäsenmaksuista lähetetään loppulasku.
                          </p>
                          <p>
                            Jäsenyyteesi kuuluvien vakuutusten voimassaolo päättyy jäsenyytesi
                            viimeiseen päivään.
                          </p>
                          <Typography variant='h5' color='primary'>
                            Organisaatio
                          </Typography>
                          <h3>{contextMembership.yhdistys_name}</h3>
                          <Typography variant='h5' color='primary'>
                            Eropäivä
                          </Typography>
                          <RadioGroup value={selectedOption}>
                            {options.map((option: any) => (
                              <FormControlLabel
                                control={<Radio />}
                                key={option.value}
                                value={option.value}
                                label={option.label}
                                onChange={handleChangeOption}
                              />
                            ))}
                          </RadioGroup>
                          {selectedOption === 'Valitse päivä' && (
                            <>
                              <FormLabel>Eroamispäivämäärä</FormLabel>
                              <FormInput
                                {...register('timeOfResignation')}
                                type='date'
                                min={today}
                              />
                            </>
                          )}
                          <ErrorMessage>{errors.timeOfResignation?.message}</ErrorMessage>
                        </div>

                        <div style={{ borderTop: '1px dotted black', paddingTop: '3vh' }}>
                          <Grid item xs={12} md={6}>
                            <Typography variant='h5' color='primary'>
                              Jäsenmaksun mahdollinen palautus
                            </Typography>
                            <FormLabel>
                              Tilinumero jäsenmaksun mahdollista palautusta varten (IBAN muodossa)
                            </FormLabel>
                            <FormInput {...register('bankAccount')} />
                            <ErrorMessage>{errors.bankAccount?.message}</ErrorMessage>
                          </Grid>
                        </div>
                      </>
                    ) : (
                      <ResignationSummary errors={errors} formState={getValues()} />
                    )}
                  </ShadowContainer>
                  <Grid
                    container
                    spacing={3}
                    direction='row'
                    wrap='wrap-reverse'
                    alignItems='center'
                    justifyContent='space-between'
                    sx={{ paddingTop: '3vh' }}>
                    {page === 0 && (
                      <Grid item xs={12} md={3} style={{ paddingTop: '3vh' }}>
                        <LaakariButton variant='outlined' onClick={handleFormReset}>
                          Takaisin
                        </LaakariButton>
                      </Grid>
                    )}
                    {page !== 0 && (
                      <Grid item xs={12} md={3} style={{ paddingTop: '3vh' }}>
                        <LaakariButton variant='outlined' onClick={() => setPage(page - 1)}>
                          Takaisin
                        </LaakariButton>
                      </Grid>
                    )}
                    {page === 0 && (
                      <Grid item xs={12} md={3} style={{ paddingTop: '3vh' }}>
                        <LaakariButton onClick={handleNextStep}>Jatka</LaakariButton>
                      </Grid>
                    )}
                    {page === 1 && (
                      <Grid item xs={12} md={3} style={{ paddingTop: '3vh', textAlign: 'right' }}>
                        <LaakariButton type='submit' disabled={isLoading}>
                          {isLoading ? <CircularProgress size={24.5} /> : 'Lähetä'}
                        </LaakariButton>
                      </Grid>
                    )}
                  </Grid>
                </form>
              </Grid>
            </Grid>
          </div>
        </>
      )}
    </>
  )
}

export default ResignationForm
