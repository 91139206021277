import styled from '@emotion/styled'
import { Button } from '@mui/material'

export const LaakariButton = styled(Button)`
  min-width: 100%;
  border-radius: 0px;
`
export const LaakariButtonNarrow = styled(Button)`
  min-width: 10vh;
  border-radius: 0px;
`

export const LaakariButtonS = styled.button`
  border: none;
  text-align: center;
  align-items: center;
  font-size: 16px;

  min-width: 10vh;
  height: 3.5vh;
  left: 11px;
  top: 16px;

  background: #00624b;
  color: white;
`

export const FormInput = styled.input`
  box-sizing: border-box;
  display: block;
  padding: 10px;
  width: 100%;
  font-size: 15px;
`

export const FormInputWide = styled.input`
  font-size: 15px;
  width: 96%;
  padding: 10px;
`

export const FormSelect = styled.select`
  font-size: 15px;
  width: 52%;
  padding: 10px;
  display: block;
`

export const FormSelectWide = styled.select`
  font-size: 15px;
  width: 100%;
  padding: 10px;
  display: block;
`

export const HeaderLinkLabel = styled.span`
  text-decoration: none;
  color: white;
`

export const InfoContainer = styled.div`
  padding-left: 3vh;
  padding-bottom: 1.5vh;
  padding-top: 1.5vh;
  padding-right: 3vh;
  box-shadow: 5px 10px;
`

export const FormLabel = styled.label`
  padding-top: 2vh;
  display: block;
`

export const ShadowContainer = styled.div`
  border: 1px solid #d6d6d6;
  border-radius: 5px;
  padding: 16px;
`

export const ShadowTableRow = styled.div`
  border: 1px solid #d6d6d6;
  border-radius: 5px;
  width: 100%;
  min-height: 8vh;
  margin-bottom: 3vh;
  padding-bottom: 1vh;
  padding-left: 2vh;
  padding-right: 2vh;
`

export const SubHeaderH2 = styled.h2`
  color: #00624b;
`

export const SubHeaderH3 = styled.h3`
  color: #00624b;
`

export const ContainerHeaderH1 = styled.h1`
  font-weight: 700;
  color: #00624b;
`

export const MainHeaderH1 = styled.h1`
  font-size: 3rem;
  color: black;
`
export const ErrorMessage = styled.p`
  color: red;
`
export const ResultsP = styled.p`
  margin-top: -4px;
  font-size: 20px;
`

export const FormSubmitButton = styled.input`
  border: none;
  text-align: center;
  align-items: center;
  font-size: 16px;

  min-width: 15vh;
  height: 3.5vh;
  left: 11px;
  top: 16px;

  background: #00624b;
  color: white;

  &:disabled {
    background: #b6b6b6;
    color: black;
  }
`
