import { format, parseISO } from 'date-fns'
import IBAN from 'iban'
import { TestFunction } from 'yup'
import { CreateJobFormData, NewJob } from '../types/job'

export const buildApiUrl = (endpointString: string, sessionId?: string, test?: boolean) => {
  if (test) {
    return `https://localhost:8080${endpointString}?key=${process.env.REACT_APP_API_KEY}`
  }

  if (sessionId) {
    return `${process.env.REACT_APP_API_BASE_URL}${endpointString}/${sessionId}?key=${process.env.REACT_APP_API_KEY}`
  } else {
    return `${process.env.REACT_APP_API_BASE_URL}${endpointString}?key=${process.env.REACT_APP_API_KEY}`
  }
  console.log('request made!')
}

const parseNestedJsonStrings = (stringifiedFormData: string): any => {
  try {
    return JSON.parse(stringifiedFormData, (key, val) => {
      if (typeof val === 'string') {
        return parseNestedJsonStrings(val)
      }
      if (typeof val === 'number') {
        return `${val}`
      }
      return val
    })
  } catch (exc) {
    return stringifiedFormData
  }
}

export const parseFormData = (data: any) => {
  return parseNestedJsonStrings(JSON.stringify(data))
}

export const parseEmploymentValues = ({
  els_soteorganisaatio,
  tyosuhdetoimiala,
  ammattinimike
}: any) => {
  const values: any = {}
  if (els_soteorganisaatio) {
    values.els_soteorganisaatio = JSON.parse(els_soteorganisaatio)
  }
  if (tyosuhdetoimiala) {
    values.tyosuhdetoimiala = JSON.parse(tyosuhdetoimiala)
  }
  if (ammattinimike) {
    values.ammattinimike = JSON.parse(ammattinimike)
  }
  return values
}

export const parseNewJobFormData = (data: CreateJobFormData): NewJob => {
  const { employment: parsedFormData } = parseNestedJsonStrings(JSON.stringify(data))
  const { ammattinimike, els_soteorganisaatio, els_tyosuhdesektori, tyosuhdetoimiala, ...rest } =
    parsedFormData
  return {
    els_ammattinimike: ammattinimike.els_koodiid,
    els_soteorganisaatio: els_soteorganisaatio.els_soteorganisaatiorekisteriid,
    els_tyosuhdesektori: els_tyosuhdesektori.attributevalue,
    els_tyosuhdetoimiala: tyosuhdetoimiala.attributevalue,
    ...rest
  }
}

export const parseDate = (date: string, dateFormat?: string) => {
  return format(parseISO(date), dateFormat || 'd.M.y')
}

export const yearList = (studentForm = false) => {
  const currentYear = new Date().getFullYear()
  const start = studentForm ? currentYear - 9 : 1930
  const end: number = currentYear

  return Array.from(Array(end - start + 1).keys())
    .map(x => x + start)
    .reverse()
}

export const validateIBAN: TestFunction<string | undefined, any> = bankAccount =>
  bankAccount !== undefined && IBAN.isValid(bankAccount)

const formatQuotedCsvValue = (value: any) =>
  value == undefined || value === '' ? '' : `"${value.toString().replace('"', '""')}"`

export const buildCsvLine = (values: any[]) => values.map(formatQuotedCsvValue).join(',')

export const replaceAll = (string: string, characterToReplace: string, replaceWith: string) => {
  return string.split(characterToReplace).join(replaceWith)
}

export const countryList = [
  {
    els_koodiid: 'd42381f6-f72b-ec11-b6e5-000d3a25c645',
    els_koodinnimi: 'Suomi'
  },
  {
    els_koodiid: 'da8cc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Afganistan'
  },
  {
    els_koodiid: 'f48cc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Ahvenanmaa'
  },
  {
    els_koodiid: '1e8ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Alankomaat'
  },
  {
    els_koodiid: 'e08cc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Albania'
  },
  {
    els_koodiid: '528dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Algeria'
  },
  {
    els_koodiid: 'd68cc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Andorra'
  },
  {
    els_koodiid: 'e68cc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Angola'
  },
  {
    els_koodiid: 'd88cc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Arabiemiirikunnat'
  },
  {
    els_koodiid: 'ea8cc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Argentiina'
  },
  {
    els_koodiid: 'e28cc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Armenia'
  },
  {
    els_koodiid: 'f08cc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Australia'
  },
  {
    els_koodiid: 'f68cc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Azerbaidzan'
  },
  {
    els_koodiid: '168dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Bahama'
  },
  {
    els_koodiid: '048dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Bahrain'
  },
  {
    els_koodiid: 'fc8cc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Bangladesh'
  },
  {
    els_koodiid: 'fa8cc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Barbados'
  },
  {
    els_koodiid: 'fe8cc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Belgia'
  },
  {
    els_koodiid: '208dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Belize'
  },
  {
    els_koodiid: '088dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Benin'
  },
  {
    els_koodiid: '188dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Bhutan'
  },
  {
    els_koodiid: '108dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Bolivia'
  },
  {
    els_koodiid: 'f88cc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Bosnia ja Hertsegovina'
  },
  {
    els_koodiid: '1c8dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Botswana'
  },
  {
    els_koodiid: '148dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Brasilia'
  },
  {
    els_koodiid: '0e8dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Brunei Darussalam'
  },
  {
    els_koodiid: '028dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Bulgaria'
  },
  {
    els_koodiid: '008dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Burkina Faso'
  },
  {
    els_koodiid: 'cc8dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Caymansaaret'
  },
  {
    els_koodiid: '328dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Chile'
  },
  {
    els_koodiid: '3a8dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Costa Rica'
  },
  {
    els_koodiid: '4a8dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Djibouti'
  },
  {
    els_koodiid: '4e8dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Dominica'
  },
  {
    els_koodiid: '508dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Dominikaaninen tasavalta'
  },
  {
    els_koodiid: '548dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Ecuador'
  },
  {
    els_koodiid: '588dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Egypti'
  },
  {
    els_koodiid: '748ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'El Salvador'
  },
  {
    els_koodiid: '5c8dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Eritrea'
  },
  {
    els_koodiid: '5e8dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Espanja'
  },
  {
    els_koodiid: 'c08ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Etelä-Afrikka'
  },
  {
    els_koodiid: '708ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Etelä-Sudan'
  },
  {
    els_koodiid: '608dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Etiopia'
  },
  {
    els_koodiid: '648dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Falklandinsaaret'
  },
  {
    els_koodiid: '348ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Filippiinit'
  },
  {
    els_koodiid: '688dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Färsaaret'
  },
  {
    els_koodiid: '6c8dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Gabon'
  },
  {
    els_koodiid: '7e8dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Gambia'
  },
  {
    els_koodiid: '728dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Georgia'
  },
  {
    els_koodiid: '788dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Ghana'
  },
  {
    els_koodiid: '7a8dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Gibraltar'
  },
  {
    els_koodiid: '7c8dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Grönlanti'
  },
  {
    els_koodiid: '828dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Guadeloupe'
  },
  {
    els_koodiid: '8c8dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Guam'
  },
  {
    els_koodiid: '8a8dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Guatemala'
  },
  {
    els_koodiid: '768dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Guernsey'
  },
  {
    els_koodiid: '808dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Guinea'
  },
  {
    els_koodiid: '8e8dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Guinea-Bissau'
  },
  {
    els_koodiid: '9a8dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Haiti'
  },
  {
    els_koodiid: '968dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Honduras'
  },
  {
    els_koodiid: '928dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Hongkong'
  },
  {
    els_koodiid: '9e8dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Indonesia'
  },
  {
    els_koodiid: 'a68dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Intia'
  },
  {
    els_koodiid: 'aa8dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Irak'
  },
  {
    els_koodiid: 'ac8dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Iran'
  },
  {
    els_koodiid: 'a08dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Irlanti'
  },
  {
    els_koodiid: 'ae8dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Islanti'
  },
  {
    els_koodiid: 'a28dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Israel'
  },
  {
    els_koodiid: 'b08dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Italia'
  },
  {
    els_koodiid: 'ee8cc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Itävalta'
  },
  {
    els_koodiid: 'b48dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Jamaika'
  },
  {
    els_koodiid: 'b88dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Japani'
  },
  {
    els_koodiid: 'bc8ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Jemen'
  },
  {
    els_koodiid: 'b28dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Jersey'
  },
  {
    els_koodiid: 'b68dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Jordania'
  },
  {
    els_koodiid: 'be8dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Kambodza'
  },
  {
    els_koodiid: '348dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Kamerun'
  },
  {
    els_koodiid: '228dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Kanada'
  },
  {
    els_koodiid: '3e8dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Kap Verde'
  },
  {
    els_koodiid: 'ce8dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Kazakstan'
  },
  {
    els_koodiid: 'ba8dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Kenia'
  },
  {
    els_koodiid: '288dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Keski-Afrikan tasavalta'
  },
  {
    els_koodiid: '368dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Kiina'
  },
  {
    els_koodiid: 'bc8dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Kirgisia'
  },
  {
    els_koodiid: '388dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Kolumbia'
  },
  {
    els_koodiid: '268dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Kongon demokraattinen tasavalta'
  },
  {
    els_koodiid: '2a8dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Kongon tasavalta'
  },
  {
    els_koodiid: '248dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Kookossaaret'
  },
  {
    els_koodiid: 'c68dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Korean demokraattinen kansantasavalta'
  },
  {
    els_koodiid: 'c88dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Korean tasavalta'
  },
  {
    els_koodiid: 'ba8ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Kosovo'
  },
  {
    els_koodiid: '868dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Kreikka'
  },
  {
    els_koodiid: '988dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Kroatia'
  },
  {
    els_koodiid: '3c8dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Kuuba'
  },
  {
    els_koodiid: 'ca8dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Kuwait'
  },
  {
    els_koodiid: '448dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Kypros'
  },
  {
    els_koodiid: 'd08dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Laos'
  },
  {
    els_koodiid: 'e28dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Latvia'
  },
  {
    els_koodiid: 'dc8dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Lesotho'
  },
  {
    els_koodiid: 'd28dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Libanon'
  },
  {
    els_koodiid: 'da8dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Liberia'
  },
  {
    els_koodiid: 'e48dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Libya'
  },
  {
    els_koodiid: 'd68dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Liechtenstein'
  },
  {
    els_koodiid: 'de8dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Liettua'
  },
  {
    els_koodiid: 'e08dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Luxemburg'
  },
  {
    els_koodiid: 'fa8dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Macao'
  },
  {
    els_koodiid: 'ee8dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Madagaskar'
  },
  {
    els_koodiid: '088ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Malediivit'
  },
  {
    els_koodiid: '0e8ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Malesia'
  },
  {
    els_koodiid: 'f48dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Mali'
  },
  {
    els_koodiid: '048ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Malta'
  },
  {
    els_koodiid: 'a48dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Mansaari'
  },
  {
    els_koodiid: 'e68dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Marokko'
  },
  {
    els_koodiid: '008ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Mauritania'
  },
  {
    els_koodiid: '068ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Mauritius'
  },
  {
    els_koodiid: '0c8ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Meksiko'
  },
  {
    els_koodiid: 'ea8dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Moldova'
  },
  {
    els_koodiid: 'e88dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Monaco'
  },
  {
    els_koodiid: 'f88dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Mongolia'
  },
  {
    els_koodiid: 'ec8dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Montenegro'
  },
  {
    els_koodiid: '108ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Mosambik'
  },
  {
    els_koodiid: 'f68dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Myanmar'
  },
  {
    els_koodiid: '128ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Namibia'
  },
  {
    els_koodiid: '248ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Nauru'
  },
  {
    els_koodiid: '228ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Nepal'
  },
  {
    els_koodiid: '1c8ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Nicaragua'
  },
  {
    els_koodiid: '168ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Niger'
  },
  {
    els_koodiid: '1a8ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Nigeria'
  },
  {
    els_koodiid: '208ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Norja'
  },
  {
    els_koodiid: '2e8dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Norsunluurannikko'
  },
  {
    els_koodiid: '2a8ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Oman'
  },
  {
    els_koodiid: '368ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Pakistan'
  },
  {
    els_koodiid: '448ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Palau'
  },
  {
    els_koodiid: '408ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Palestiina'
  },
  {
    els_koodiid: '2c8ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Panama'
  },
  {
    els_koodiid: '328ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Papua-Uusi-Guinea'
  },
  {
    els_koodiid: '468ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Paraguay'
  },
  {
    els_koodiid: '2e8ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Peru'
  },
  {
    els_koodiid: 'f28dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Pohjois-Makedonian tasavalta'
  },
  {
    els_koodiid: '428ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Portugali'
  },
  {
    els_koodiid: '3e8ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Puerto Rico'
  },
  {
    els_koodiid: '388ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Puola'
  },
  {
    els_koodiid: '488ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Qatar'
  },
  {
    els_koodiid: '6a8dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Ranska'
  },
  {
    els_koodiid: '4c8ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Romania'
  },
  {
    els_koodiid: '528ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Ruanda'
  },
  {
    els_koodiid: '4e6779fc-f72b-ec11-b6e5-000d3a25c645',
    els_koodinnimi: 'Ruotsi'
  },
  {
    els_koodiid: '488dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Saksa'
  },
  {
    els_koodiid: 'c28ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Sambia'
  },
  {
    els_koodiid: 'b68ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Samoa'
  },
  {
    els_koodiid: '688ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'San Marino'
  },
  {
    els_koodiid: '548ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Saudi-Arabia'
  },
  {
    els_koodiid: '6a8ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Senegal'
  },
  {
    els_koodiid: '4e8ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Serbia'
  },
  {
    els_koodiid: '668ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Sierra Leone'
  },
  {
    els_koodiid: '5c8ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Singapore'
  },
  {
    els_koodiid: '648ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Slovakia'
  },
  {
    els_koodiid: '608ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Slovenia'
  },
  {
    els_koodiid: '6c8ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Somalia'
  },
  {
    els_koodiid: 'd88dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Sri Lanka'
  },
  {
    els_koodiid: '5a8ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Sudan'
  },
  {
    els_koodiid: '6e8ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Suriname'
  },
  {
    els_koodiid: '2c8dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Sveitsi'
  },
  {
    els_koodiid: '7a8ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Swazimaa'
  },
  {
    els_koodiid: '788ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Syyria'
  },
  {
    els_koodiid: '868ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Tadzikistan'
  },
  {
    els_koodiid: '988ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Taiwan'
  },
  {
    els_koodiid: '9a8ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Tansania'
  },
  {
    els_koodiid: '4c8dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Tanska'
  },
  {
    els_koodiid: '848ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Thaimaa'
  },
  {
    els_koodiid: '828ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Togo'
  },
  {
    els_koodiid: '908ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Tonga'
  },
  {
    els_koodiid: '948ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Trinidad ja Tobago'
  },
  {
    els_koodiid: '7e8ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Tsad'
  },
  {
    els_koodiid: '468dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Tsekki'
  },
  {
    els_koodiid: 'a966477c-1e77-ee11-8179-6045bd8d45f9',
    els_koodinnimi: 'Tsekkoslovakia'
  },
  {
    els_koodiid: '8e8ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Tunisia'
  },
  {
    els_koodiid: '928ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Turkki'
  },
  {
    els_koodiid: '8c8ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Turkmenistan'
  },
  {
    els_koodiid: '9e8ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Uganda'
  },
  {
    els_koodiid: '9c8ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Ukraina'
  },
  {
    els_koodiid: '9c8dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Unkari'
  },
  {
    els_koodiid: 'a48ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Uruguay'
  },
  {
    els_koodiid: '148ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Uusi-Kaledonia'
  },
  {
    els_koodiid: '288ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Uusi-Seelanti'
  },
  {
    els_koodiid: 'a68ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Uzbekistan'
  },
  {
    els_koodiid: '1e8dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Valko-Venäjä'
  },
  {
    els_koodiid: 'b88ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Vanuatu'
  },
  {
    els_koodiid: 'ac8ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Venezuela'
  },
  {
    els_koodiid: '508ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Venäjä'
  },
  {
    els_koodiid: 'b48ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Vietnam'
  },
  {
    els_koodiid: '568dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Viro'
  },
  {
    els_koodiid: '6e8dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Yhdistynyt kuningaskunta'
  },
  {
    els_koodiid: 'a28ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Yhdysvallat'
  },
  {
    els_koodiid: 'c48ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Zimbabwe'
  }
]

export const universityList = [
  {
    els_koodinnimi: 'Helsingin yliopisto',
    els_koodiid: '48428bdb-de8f-ec11-b400-000d3a4bbe45'
  },
  {
    els_koodinnimi: 'Itä-Suomen yliopisto',
    els_koodiid: 'd1d34d00-df8f-ec11-b400-000d3a4bbe45'
  },
  {
    els_koodinnimi: 'Oulun yliopisto',
    els_koodiid: 'fd8135e1-af23-ed11-b83d-000d3a26cec1'
  },
  {
    els_koodinnimi: 'Tampereen yliopisto',
    els_koodiid: 'f8959db1-9e61-ed11-9561-6045bd87c782'
  },
  {
    els_koodinnimi: 'Turun yliopisto',
    els_koodiid: 'efe6685a-a72e-ed11-9db1-6045bd8a7298'
  }
]

export const universityCountryList = [
  {
    els_koodiid: 'da8cc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Afganistan'
  },
  {
    els_koodiid: '1e8ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Alankomaat'
  },
  {
    els_koodiid: '528dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Algeria'
  },
  {
    els_koodiid: 'ea8cc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Argentiina'
  },
  {
    els_koodiid: 'e28cc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Armenia'
  },
  {
    els_koodiid: 'f08cc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Australia'
  },
  {
    els_koodiid: 'f68cc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Azerbaidzan'
  },
  {
    els_koodiid: 'fc8cc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Bangladesh'
  },
  {
    els_koodiid: 'fe8cc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Belgia'
  },
  {
    els_koodiid: '108dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Bolivia'
  },
  {
    els_koodiid: 'f88cc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Bosnia ja Hertsegovina'
  },
  {
    els_koodiid: '148dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Brasilia'
  },
  {
    els_koodiid: '028dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Bulgaria'
  },
  {
    els_koodiid: '328dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Chile'
  },
  {
    els_koodiid: '588dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Egypti'
  },
  {
    els_koodiid: '748ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'El Salvador'
  },
  {
    els_koodiid: '5e8dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Espanja'
  },
  {
    els_koodiid: '608dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Etiopia'
  },
  {
    els_koodiid: '348ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Filippiinit'
  },
  {
    els_koodiid: '728dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Georgia'
  },
  {
    els_koodiid: 'a68dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Intia'
  },
  {
    els_koodiid: 'aa8dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Irak'
  },
  {
    els_koodiid: 'ac8dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Iran'
  },
  {
    els_koodiid: 'a08dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Irlanti'
  },
  {
    els_koodiid: 'ae8dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Islanti'
  },
  {
    els_koodiid: '6e8dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Yhdistynyt kuningaskunta'
  },
  {
    els_koodiid: 'a28dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Israel'
  },
  {
    els_koodiid: 'b08dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Italia'
  },
  {
    els_koodiid: 'ee8cc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Itävalta'
  },
  {
    els_koodiid: '228dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Kanada'
  },
  {
    els_koodiid: 'ce8dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Kazakstan'
  },
  {
    els_koodiid: '368dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Kiina'
  },
  {
    els_koodiid: 'bc8dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Kirgisia'
  },
  {
    els_koodiid: '388dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Kolumbia'
  },
  {
    els_koodiid: '268dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Kongon demokraattinen tasavalta'
  },
  {
    els_koodiid: 'ba8ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Kosovo'
  },
  {
    els_koodiid: '868dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Kreikka'
  },
  {
    els_koodiid: '988dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Kroatia'
  },
  {
    els_koodiid: '3c8dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Kuuba'
  },
  {
    els_koodiid: '448dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Kypros'
  },
  {
    els_koodiid: 'e28dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Latvia'
  },
  {
    els_koodiid: 'e48dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Libya'
  },
  {
    els_koodiid: 'de8dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Liettua'
  },
  {
    els_koodiid: 'f8959db1-9e61-ed11-9561-6045bd87c782',
    els_koodinnimi: 'Makedonia'
  },
  {
    els_koodiid: '0e8ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Malesia'
  },
  {
    els_koodiid: 'e68dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Marokko'
  },
  {
    els_koodiid: '0c8ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Meksiko'
  },
  {
    els_koodiid: 'ea8dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Moldova'
  },
  {
    els_koodiid: '228ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Nepal'
  },
  {
    els_koodiid: '1a8ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Nigeria'
  },
  {
    els_koodiid: '208ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Norja'
  },
  {
    els_koodiid: '368ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Pakistan'
  },
  {
    els_koodiid: '2c8ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Panama'
  },
  {
    els_koodiid: '468ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Paraguay'
  },
  {
    els_koodiid: '2e8ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Peru'
  },
  {
    els_koodiid: '428ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Portugali'
  },
  {
    els_koodiid: '388ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Puola'
  },
  {
    els_koodiid: '6a8dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Ranska'
  },
  {
    els_koodiid: '4c8ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Romania'
  },
  {
    els_koodiid: '4e6779fc-f72b-ec11-b6e5-000d3a25c645',
    els_koodinnimi: 'Ruotsi'
  },
  {
    els_koodiid: '488dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Saksa'
  },
  {
    els_koodiid: '4e8ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Serbia'
  },
  {
    els_koodiid: '668ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Sierra Leone'
  },
  {
    els_koodiid: '648ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Slovakia'
  },
  {
    els_koodiid: '608ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Slovenia'
  },
  {
    els_koodiid: '6c8ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Somalia'
  },
  {
    els_koodiid: '5a8ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Sudan'
  },
  {
    els_koodiid: '2c8dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Sveitsi'
  },
  {
    els_koodiid: '788ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Syyria'
  },
  {
    els_koodiid: '868ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Tadzikistan'
  },
  {
    els_koodiid: '4c8dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Tanska'
  },
  {
    els_koodiid: '468dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Tsekki'
  },
  {
    els_koodiid: '928ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Turkki'
  },
  {
    els_koodiid: '9c8ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Ukraina'
  },
  {
    els_koodiid: '9c8dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Unkari'
  },
  {
    els_koodiid: 'a48ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Uruguay'
  },
  {
    els_koodiid: 'a68ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Uzbekistan'
  },
  {
    els_koodiid: '1e8dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Valko-Venäjä'
  },
  {
    els_koodiid: 'ac8ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Venezuela'
  },
  {
    els_koodiid: '508ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Venäjä'
  },
  {
    els_koodiid: 'b48ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Vietnam'
  },
  {
    els_koodiid: '568dc676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Viro'
  },
  {
    els_koodiid: 'a28ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Yhdysvallat'
  },
  {
    els_koodiid: 'c48ec676-1563-ec11-8f8f-6045bd8bcd35',
    els_koodinnimi: 'Zimbabwe'
  },
  {
    els_koodiid: '00000000-0000-0000-0000-000000000000',
    els_koodinnimi: 'Muu'
  }
]
